<template>
  <vs-col vs-type="flex" vs-justify="center" vs-align="center">
    <message-nav :is-left="isLeft"></message-nav>

    <div class="mobile-border">
      <div class="mobile-content vs-con-loading__container">
        <Messages />
        <!--<mobileViewAddLinkBtns />-->
      </div>
    </div>

    <message-nav></message-nav>
  </vs-col>
</template>
<script>
import Messages from './mobileView/Messages'
import MessageNav from './messageNav/MessageNav'

export default {
  name: 'MobileView',
  data: () => {
    return {
      isLeft: true
    }
  },
  components: {
    Messages,
    MessageNav
  }
}
</script>
<style lang="scss" scoped>
.mobile-border {
  $mobile-background-color: #ff9f38;
  .mobile-content {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 45px;
    text-align: center;
    background-image: url('../../../../../assets/images/iphonex_preview_silver.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    height: 600px;
    width: 300px;
    padding: 50px 25px 35px 25px;
  }
  .mobile-divider {
    background: $mobile-background-color;
    height: 10px;
  }
}
</style>
