<template>
  <div class="user-says">
    <h3 class="text-center">
      {{ lang.botMaker.botEditor.userSays.title[languageSelected] }}
    </h3>
    <br />
    <vs-card v-if="!isIntentionSelected">
      <ConditionalMessage />
    </vs-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
// import TextMessage from './userSays/TextMessage'
// import ImageMessage from './userSays/ImageMessage'
// import AudioMessage from './userSays/AudioMessage'
// import RegexMessage from './userSays/RegexMessage'

export default {
  components: {
    // RegexMessage,
    // TextMessage,
    // ImageMessage,
    // AudioMessage,
    // IntentionMessage: () => import('./userSays/IntentionMessage.vue'),
    // EntityMessage: () => import('./userSays/EntityMessage.vue'),
    ConditionalMessage: () => import('./userSays/ConditionalMessage.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'userMessageInEdit',
      'botMessageInEdit',
      'activeUserTab',
      'selectedIntention'
    ]),
    activeTab: {
      get() {
        return this.activeUserTab
      },
      set(index) {
        this.UPDATE_USER_TAB_INDEX(index)
      }
    },
    isIntentionSelected() {
      return Object.keys(this.selectedIntention).length
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'UPDATE_USER_MESSAGE_TYPE',
      'UPDATE_USER_TAB_INDEX',
      'SET_INTENTION_SELECTED',
      'SET_ENTITY_SELECTED',
      'SET_NEW_ENTITY_NAME'
    ]),
    switchToMessageType() {
      this.SET_INTENTION_SELECTED()
      this.SET_ENTITY_SELECTED()
      this.SET_NEW_ENTITY_NAME()

      // this.UPDATE_USER_MESSAGE_TYPE(type)
    }
  }
}
</script>

<style lang="scss">
.user-says {
  &__card {
    // text-align: left;
    // background: transparent;
    .con-ul-tabs {
      background: white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      .vs-tabs--ul {
        padding: 10px 10px 0 10px;
      }
    }
    .con-slot-tabs {
      margin-top: 20px;
      padding: 0;
      .vs-tabs--content {
        padding: 0;
      }
    }
  }
  .con-vs-card {
    padding: 20px;
    .vs-card--content {
      margin-bottom: 0;
    }
  }
}
</style>
